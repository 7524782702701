import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { getPublicClient } from '@neo1/client/lib/rpc/client';
import config, { getConfigValue } from 'config';
import { useWindowContext, WinSize, lgWidth } from 'contexts/window';
import InfoBox from 'components/elements/InfoBox';
import Tooltip from 'components/elements/Tooltip';
import LoadPlaceholder from 'components/elements/LoadPlaceholder';
import Disclaimer from 'components/elements/Disclaimer';
import Copyright from 'components/elements/Copyright';

import colors from 'styles/colors';
import Logo from './Logo';
import Headline from './Headline';

import BrowserNotSupportedBanner from '../App/BrowserNotSuppportedBanner';
import { ReactComponent as FlourishCropped } from '../../../../public/images/backgrounds/flourish_cropped.svg';
import { ReactComponent as FlourishHalf } from '../../../../public/images/backgrounds/flourish_half.svg';
import { ReactComponent as AmexGbtLogo } from '../../../../public/images/logos/amexLightmode.svg';
import styles from './PublicPage.module.css';
import SkipToContentLink from '../App/SkipToContent';

declare let ENV: string;

type Props = {
  title?: React.ReactNode;
  subTitle?: string;
  error?: string;
  showTitle?: boolean;
  children: React.ReactNode;
  description?: string;
  showFlourish?: boolean;
};

const mainContentIdPublicPage = 'mainContentPublicPage';

const PublicPage = ({
  children,
  title,
  subTitle,
  showTitle,
  error,
  description,
  showFlourish,
}: Props) => {
  const [numberVersion, setNumberVersion] = useState(null);
  const [apiTechnicalVersion, setApiTechnicalVersion] = useState(null);

  const { winSize, winWidth } = useWindowContext();

  const Flourish = winWidth < lgWidth ? FlourishHalf : FlourishCropped;

  const buttonTitle = 'Copy version';

  async function fetchApiTechnicalVersion() {
    try {
      const result = await getPublicClient().sendCommand({
        method: 'get_version',
        params: {},
        id: 1,
      });
      setApiTechnicalVersion(result);
    } catch {
      setApiTechnicalVersion('N/A');
      throw new Error('API technical version not available, is the API down?');
    }
  }

  async function fetchUiCommercialVersion() {
    let results;
    if (ENV === 'dist') {
      results = await fetch(`${config.assetsPath}/commercial_version.txt`);
    }
    if (results && results.status === 200) {
      const text = await results.text();
      setNumberVersion(text);
    } else {
      setNumberVersion(config.buildVersion);
    }
  }

  const copyVersionsToClipboard = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    target.style.textShadow = `0px 0px 20px ${colors.white}`;
    navigator.clipboard.writeText(
      `Neo1: ${numberVersion}, UI: ${config.buildVersion}, API: ${apiTechnicalVersion}`,
    );
    setTimeout(() => {
      target.style.textShadow = '';
    }, 350);
  };

  useEffect(() => {
    if (title) {
      document.title = `Neo1 - ${title}`;
    }
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', description || document.title);
    fetchUiCommercialVersion().catch(() => {});
    fetchApiTechnicalVersion().catch(() => {});
  }, []);

  return (
    <>
      <SkipToContentLink mainContentId={mainContentIdPublicPage} />
      <div className={styles.container}>
        {getConfigValue('unsupportedBrowserBanner') ? (
          <BrowserNotSupportedBanner className={styles.banner} />
        ) : null}
        <div className={styles.inner}>
          <header className={styles.header}>
            <Link to="/">
              <Logo />
            </Link>
            <div
              className={classNames(
                styles.headline,
                [WinSize.sm, WinSize.xs].includes(winSize)
                  ? 'textLSemibold'
                  : 'header1',
              )}
            >
              <Headline />
            </div>
          </header>
          <main
            className={classNames(
              styles.main,
              showFlourish && styles.withFlourish,
              'neo1AppMain',
            )}
            id={mainContentIdPublicPage}
            tabIndex={-1}
          >
            {showFlourish && <Flourish className={styles.flourish} />}
            <div className={styles.mainInner}>
              {showTitle && title && <h2 className={styles.title}>{title}</h2>}
              {subTitle && <div>{subTitle}</div>}
              {error && <InfoBox type="error">{error}</InfoBox>}
              {children}
            </div>
          </main>
          <footer className={styles.footer}>
            <AmexGbtLogo className={styles.amexLogo} />
            <div className={classNames(styles.version, 'textM')}>
              {numberVersion ? (
                <Tooltip
                  className={styles.versionTooltip}
                  content={
                    <>
                      <p>(Click to copy)</p>
                      <p>UI technical version: {config.buildVersion}</p>
                      <p>API technical version: {apiTechnicalVersion}</p>
                    </>
                  }
                >
                  <button
                    type="button"
                    title={buttonTitle}
                    aria-label={buttonTitle}
                    onClick={copyVersionsToClipboard}
                  >
                    {numberVersion}
                  </button>
                </Tooltip>
              ) : (
                <LoadPlaceholder />
              )}
            </div>
            <div className={styles.disclaimer}>
              <Disclaimer />
            </div>
            <div className={styles.copyright}>
              <Copyright />
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

PublicPage.defaultProps = {
  title: undefined,
  subTitle: undefined,
  error: undefined,
  showTitle: true,
  description: undefined,
  showFlourish: false,
};

export default PublicPage;
