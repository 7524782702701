import DropDownList from 'modules/Dashboard/Widgets/TravelOnboardingWidget/DropDownList';
import Button, { ButtonSize, ButtonTheme } from 'components/elements/Button';
import { Dropdown } from '../types';

type Props = {
  dropdown: Dropdown;
};

const DropdownBtn = ({ dropdown }: Props) => (
  <DropDownList items={dropdown.items}>
    <Button
      label={dropdown.label}
      iconName="down"
      iconPosition="trailing"
      theme={dropdown.btnTheme || ButtonTheme.Outlined}
      iconOnly={dropdown.btnIconOnly || false}
      size={dropdown.btnSize || ButtonSize.Medium}
      disabled={dropdown.disabled}
    />
  </DropDownList>
);

export default DropdownBtn;
