import { Placement } from '@popperjs/core';
import classNames from 'classnames';
import Popover from 'components/elements/Popover';
import { ReactNode } from 'react';
import styles from './Tooltip.module.css';

type Props = {
  children: ReactNode;
  content: ReactNode;
  theme?: 'normal' | 'warning' | 'error';
  className?: string;
  targetClassName?: string;
  placement?: Placement;
  testId?: string;
  isVisible?: boolean;
  isInteractive?: boolean;
};

const Tooltip = ({
  children,
  content,
  theme,
  className,
  targetClassName,
  placement,
  testId,
  isVisible,
  isInteractive,
}: Props) =>
  content ? (
    <Popover
      className={classNames(styles.tooltip, className, styles[theme])}
      targetClassName={targetClassName}
      content={content}
      testId={testId}
      placement={placement}
      isVisible={isVisible}
      isInteractive={isInteractive}
    >
      {children}
    </Popover>
  ) : (
    <>{children}</>
  );

Tooltip.defaultProps = {
  theme: 'normal',
  className: undefined,
  targetClassName: undefined,
  placement: undefined,
  testId: undefined,
  isVisible: undefined,
  isInteractive: true,
};

export default Tooltip;
