import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Route, Switch, useLocation } from 'react-router-dom';
import isFunction from 'lodash/isFunction';
import {
  actingCompanyHasRightFeatures,
  actingUserHasRightLevel,
  actingUserHasRightRoles,
  isAdmin,
  isCompanyUser,
} from '@neo1/client/lib/entities/user/utils';
import { CompanyUser } from '@neo1/client/lib/entities/user/types';
import { selectIsServiceConfigured } from 'redux/accountingIntegrations/selector';
import { getConfigValue } from 'config';
import { RoutesContextProvider } from 'contexts/routes';
import { useLayoutContext } from 'contexts/layout';
import {
  selectActingUser,
  selectActingCompany,
  selectIsLoggedInAs,
} from 'modules/Authentification/redux/selectors';
import ActivateCompany from 'modules/Authentification/ActivateCompany';
import { acceptTermsAndConditions } from 'modules/Authentification/redux/thunks';
import { notifyError } from 'modules/App/redux/notifications/toaster/thunks';
import ConfigureAccounting from 'components/forms/ConfigureAccounting';
import { mapRouteConfigToRoutes, AppRoute } from 'config/routes/utils';
import NotFound from 'components/layout/App/NotFound';
import Banner from 'components/composed/Banner';
import QuickBooksModal from 'modules/Settings/General/Integrations/LoginAccounting/Quickbooks';
import { mdWidth, useWindowContext } from 'contexts/window';
import {
  CompanyStatus,
  CompanyExtractService,
} from '@neo1/client/lib/entities/company/constants';
import { isConnectedToExternalAccountingSystem } from '@neo1/client/lib/entities/company/utils';
import { mainContentId } from 'components/layout/Page';
import PendingBanner from './PendingBanner';
import FreshdeskWidget from './FreshdeskWidget';
import MobileHeader from './MobileHeader';
import ProfileMenu from './ProfileMenu';
import NavigationMenu from './NavigationMenu';
import BrowserNotSupportedBanner from './BrowserNotSuppportedBanner';
import EulaModal from './TermsConditions/EulaModal';
import styles from './AppLayout.module.css';
import Logo from './Logo';
import LoggedInAsBanner from './LoggedInAsBanner';
import SkipToContentLink from './SkipToContent';

type Props = {
  currentPath: string;
  routes: AppRoute[];
};

const primaryNavProps = {
  role: 'navigation',
  'aria-label': 'primary',
};

const AppLayout = ({ currentPath, routes }: Props) => {
  const dispatch = useDispatch();
  const windowContext = useWindowContext();
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isEditAccountingOpen, setIsEditAccountingOpen] = useState(false);
  const isLoggedInAs = useSelector(selectIsLoggedInAs);
  const user = useSelector(selectActingUser);
  const company = useSelector(selectActingCompany);
  const [isActivateCompanyOpen, setIsActivateCompanyOpen] = useState(false);
  const accountingService = company && company.accountingLink;
  const isAccountingConfigured = useSelector(
    selectIsServiceConfigured(accountingService),
  );
  const { pathname } = useLocation();

  const {
    state: { isProfileMenuOpen, isNavMenuOpen },
    actions: { closeNavMenu, closeAllMenus },
  } = useLayoutContext();

  useEffect(() => {
    if (!company?.id) return;

    const isCompanyPendingActivation =
      company && company.status === CompanyStatus.PendingActivation;
    if (isCompanyPendingActivation) {
      setIsActivateCompanyOpen(true);
    }

    if (
      company &&
      isCompanyUser(user) &&
      !isLoggedInAs &&
      (!user.latestAcceptedEula || !user.latestAcceptedEula.isLatest)
    ) {
      setIsTermsModalOpen(true);
    }

    const canShowConfigureModal =
      isConnectedToExternalAccountingSystem(company) && !isAccountingConfigured;

    if (canShowConfigureModal) {
      setIsEditAccountingOpen(true);
    }
  }, [company?.id]);

  useEffect(() => {
    closeAllMenus();
  }, [currentPath, windowContext.winSize]);

  /**
   * Asserts if given menu item is visible or not in the menu
   */
  const canShowMenu = ({ menu }: any) => {
    const isVisible = () => {
      if (isFunction(menu.show)) {
        return menu.show({ user, company });
      }
      return true;
    };

    return (
      menu &&
      isVisible() &&
      (!menu.userRoles ||
        actingUserHasRightRoles(menu.userRoles, user as CompanyUser)) &&
      (!menu.userLevels || actingUserHasRightLevel(menu.userLevels, user)) &&
      (!menu.companyFeatures ||
        actingCompanyHasRightFeatures(menu.companyFeatures, company))
    );
  };

  /**
   * Asserts if given route is accessible
   */
  const canShowRoute = (route: AppRoute) =>
    isFunction(route.show) ? route.show({ pathname, user, company }) : true;

  const menu = routes.reduce((appMenu, route) => {
    if (canShowRoute(route) && canShowMenu(route)) {
      return [...appMenu, route];
    }

    return appMenu;
  }, []);

  const renderContent = () => (
    <Switch>
      {mapRouteConfigToRoutes(routes, canShowRoute)}
      <Route component={NotFound} />
    </Switch>
  );

  const companyStatus = company && company.status;

  const isCompanyActive = companyStatus === CompanyStatus.Active;
  const isCompanyPendingActivation =
    companyStatus === CompanyStatus.PendingActivation;
  const isCompanyFinalizingActivation =
    companyStatus === CompanyStatus.ActivationInProgress ||
    companyStatus === CompanyStatus.DueDiligenceFailed;

  const showActivationModal =
    isCompanyUser(user) &&
    isAdmin(user) &&
    !isTermsModalOpen &&
    isActivateCompanyOpen &&
    !isCompanyActive;

  const onAcceptTerm = async (version: string) => {
    await dispatch(acceptTermsAndConditions(version));
  };
  const renderAccountingConf = () => {
    if (accountingService === CompanyExtractService.QuickBooks)
      return (
        <QuickBooksModal
          isOpen
          onClose={() => setIsEditAccountingOpen(false)}
        />
      );
    return (
      <ConfigureAccounting
        onClose={() => setIsEditAccountingOpen(false)}
        accountingService={accountingService}
      />
    );
  };

  return (
    <RoutesContextProvider routes={routes}>
      <>
        <SkipToContentLink mainContentId={mainContentId} />
        {isLoggedInAs && <LoggedInAsBanner />}
        <div className={styles.container}>
          <section className={styles.main}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              onClick={closeAllMenus}
              className={classNames(styles.menuBackground, {
                [styles.isVisible]: isNavMenuOpen || isProfileMenuOpen,
              })}
            />
            {windowContext.winWidth < mdWidth ? (
              <>
                <MobileHeader />
                <nav
                  data-testid="navigationMenu"
                  className={classNames(
                    styles.leftPane,
                    isNavMenuOpen && styles.isVisible,
                  )}
                  // copies same props for primary navigation.
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...primaryNavProps}
                >
                  <NavigationMenu
                    menu={menu}
                    onNavigation={closeAllMenus}
                    onClickOnClose={closeNavMenu}
                  />
                </nav>
              </>
            ) : (
              <>
                <nav
                  data-testid="navigationMenu"
                  className={styles.navBar}
                  // copies same props for primary navigation.
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...primaryNavProps}
                >
                  <div className={styles.logoWrapper}>
                    <Logo size="sm" />
                  </div>
                  <NavigationMenu menu={menu} onNavigation={closeAllMenus} />
                </nav>
              </>
            )}
            <aside
              data-testid="profileMenu"
              className={classNames(
                styles.rightPane,
                isProfileMenuOpen && styles.isVisible,
              )}
            >
              <ProfileMenu user={user} onNavigation={closeAllMenus} />
            </aside>
            <div className={styles.content}>{renderContent()}</div>
          </section>
          {getConfigValue('unsupportedBrowserBanner') ? (
            <div className={styles.browserNotSupportedBanner}>
              <BrowserNotSupportedBanner />
            </div>
          ) : null}
          {isCompanyFinalizingActivation && (
            <Banner
              id="company_activation_in_progress_banner"
              label="Hang tight! We are currently finalizing your setup"
              description="We will be in touch in a day or two to let you know that you are fully active."
            />
          )}
          {isCompanyPendingActivation && (
            <PendingBanner
              className={classNames(styles.pendingBanner, {
                [styles.isHidden]: showActivationModal,
              })}
              isAdmin={isAdmin(user)}
              openActivateCompany={() => setIsActivateCompanyOpen(true)}
            />
          )}
          {showActivationModal && (
            <ActivateCompany
              isOpen
              onClose={() => {
                setIsActivateCompanyOpen(false);
              }}
            />
          )}
          {isTermsModalOpen && (
            <EulaModal
              companyId={company.id}
              setIsTermsModalOpen={setIsTermsModalOpen}
              isTermsModalOpen={isTermsModalOpen}
              onAccept={onAcceptTerm}
              onError={() => dispatch(notifyError)}
            />
          )}
          {isEditAccountingOpen && renderAccountingConf()}
        </div>
        <FreshdeskWidget />
      </>
    </RoutesContextProvider>
  );
};

export default AppLayout;
