export enum Actions {
  SET_EXPENSE_TYPES = 'one/settings/ExpenseTypes/SET_EXPENSE_TYPES',
  SET_EXPENSE_TYPE = 'one/settings/ExpenseTypes/SET_EXPENSE_TYPE',
  DELETE_EXPENSE_TYPE = 'one/settings/ExpenseTypes/DELETE_EXPENSE_TYPE',
}
type SetExpenseTypes = {
  type: Actions.SET_EXPENSE_TYPES;
  ids: string[];
};

type SetExpenseType = {
  type: Actions.SET_EXPENSE_TYPE;
  id: string;
};
type DeleteExpenseType = {
  type: Actions.DELETE_EXPENSE_TYPE;
  id: string;
};

export function setAll(ids: string[]): SetExpenseTypes {
  return { type: Actions.SET_EXPENSE_TYPES, ids };
}

export function set(id: string): SetExpenseType {
  return { type: Actions.SET_EXPENSE_TYPE, id };
}

export function deleteExpenseType(id: string): DeleteExpenseType {
  return { type: Actions.DELETE_EXPENSE_TYPE, id };
}

export type ExpenseActions =
  | DeleteExpenseType
  | SetExpenseTypes
  | SetExpenseType;
