import Button, { ButtonSize, ButtonTheme } from 'components/elements/Button';
import { useRef } from 'react';
import styles from './SkipToContent.module.css';

const LABEL = 'Skip to main content';
const SKIP_TO_CONTENT_LINK_ID = 'skipToContentLink';

type Props = {
  mainContentId: string;
};

const SkipToContentLink = ({ mainContentId }: Props) => {
  const linkRef = useRef<HTMLAnchorElement>();
  const handleButtonClick = () => {
    if (linkRef.current) {
      linkRef.current.click();
    }
  };
  return (
    <>
      <Button
        size={ButtonSize.Large}
        theme={ButtonTheme.Outlined}
        ariaLabel={LABEL}
        label={LABEL}
        onClick={handleButtonClick}
        className={styles.skipToContentButton}
      />
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label */}
      <a
        href={`#${mainContentId}`}
        id={SKIP_TO_CONTENT_LINK_ID}
        ref={linkRef}
        className={styles.hiddenLink}
      />
    </>
  );
};
export default SkipToContentLink;
